import React, { useState } from "react"

export default function ({
  location,
  currentSchoolLocation,
  setCurrentSchoolLocation,
  locations,
  afterClick,
}) {
  const [showOptions, setShowOptions] = useState(false)
  return (
    <div className="md:border py-8 px-8 lg:-mx-16 bg-white">
      <div className="flex justify-between items-center">
        <div className="mr-4">
          <div className="text-xs md:text-sm text-gray-600 uppercase tracking-wide font-medium">
            You're viewing
          </div>
          <div>
            Bloom Nepal School,{" "}
            <span className="font-medium capitalize text-red-800">
              {location}
            </span>
          </div>
        </div>
        <button
          className={`font-medium uppercase text-white py-2 px-4 button-outline no-select ${
            showOptions ? "bg-red-900" : "bg-blue-900"
          }`}
          onClick={() => {
            setShowOptions(!showOptions)
            // setShowOptions(true)
          }}
        >
          {showOptions ? "X" : "Change"}
        </button>
      </div>
      {showOptions ? (
        <div className="mt-8 flex-col">
          {locations.map((location, index) => (
            <button
              className={`button-outline bg-gray-600 text-white py-4 px-6 my-4 mr-4 block ${
                currentSchoolLocation === location ? "bg-red-800" : ""
              }`}
              onClick={() => {
                setCurrentSchoolLocation(location)
                setShowOptions(false)
                afterClick()
              }}
              key={index}
            >
              {" "}
              Bloom Nepal School, <span className="capitalize">{location}</span>
            </button>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  )
}
