import React, { useState, useRef, useEffect } from "react"
import Layout from "../components/layout"
import BeABloomie from "../components/beBloomie"
import SchoolSelection from "../components/schoolSelection"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { isInViewPort } from "../utils/helpers.js"
import SEO from "../components/seo"

const SchoolFacilities = ({ location, className, data }) => {
  if (location === "lalitpur") {
    return <BloomLalitpurFacilities className={className} data={data} />
  } else if (location === "itahari") {
    return <BloomItahariFacilities className={className} data={data} />
  }
}

const BloomLalitpurFacilities = ({ className, data }) => {
  return (
    <div className={` ${className}`}>
      <section className="md:mt-0 py-12 px-8 lg:border-l lg:border-b md:border-gray-600 lg:flex lg:items-center">
        <div className="lg:w-2/5 mr-8">
          <h2 className="section-head text-2xl">Location</h2>
          <div className="mt-2">
            <Img
              className="lg:hidden"
              fluid={data.lalitpurLocation.childImageSharp.fluid}
              alt="School buildings surrounded by paddy fields"
              draggable={false}
            />
          </div>
          <p className="mt-4">
            Our Lalitpur campus is located in the foothills of the Sankhadevi in
            Mahalaxmi, Lalitpur. Just a 45-minute away from the bustle of the
            busy capital city, our campus spans over an area of 30 ropanis,
            giving students enough open space for learning through/and
            recreation.
          </p>
        </div>
        <div className="hidden lg:block lg:w-3/5 mr-8 pl-8">
          <Img
            className=""
            fluid={data.lalitpurLocation.childImageSharp.fluid}
            alt="School buildings surrounded by paddy fields"
            draggable={false}
          />
        </div>
      </section>
      <section className="py-12 px-8 lg:border-r lg:border-b md:border-gray-600 md:flex md:flex-row-reverse md:items-center">
        <div className="md:w-1/2 md:pl-8">
          <h2 className="section-head text-2xl">International Exposure</h2>
          <div className="mt-4">
            <Img
              className="md:hidden"
              fluid={data.internationalExposure.childImageSharp.fluid}
              alt="A student in cultural dress giving gift to her teacher"
              draggable={false}
            />
          </div>
          <p className="mt-4">
            We are graced by the visit of volunteers, teachers, interns and
            friends from Europe and America almost all year long.
          </p>
          <p className="mt-2">
            This provides an incredible opportunity for students to learn about
            culture beyond the local along with learning the skillset this
            international body comes to share.
          </p>
        </div>
        <div className="hidden md:block md:w-1/2 md:pr-8">
          <Img
            className="block"
            fluid={data.internationalExposure.childImageSharp.fluid}
            alt="A student in cultural dress giving gift to her teacher"
            draggable={false}
          />
        </div>
      </section>
      <section
        className="
      py-12 px-8 lg:border-l lg:border-b md:border-gray-600"
      >
        <h2 className="section-head text-2xl md:text-center">
          Residence & Day Scholars
        </h2>
        <p className="mt-4 md:px-12 md:mt-6">
          Students can stay with us in the residence halls or may choose to
          commute from home if that’s an option. This mixed residential setting
          allows us to create a diverse cultural experience without forgetting
          the local taste of things.
        </p>
      </section>
      <section className="md:flex mt-16 md:items-center lg:border-r lg:border-b md:border-gray-600 ">
        <div className="mt-2 md:w-1/2 md:pr-8">
          <h4 className="section-head text-xl mt-4 text-red-800">
            Resident System
          </h4>
          <Img
            className="md:hidden"
            fluid={data.lalitpurResidence.childImageSharp.fluid}
            alt="Students watching a video on a laptop"
            draggable={false}
          />
          <p className="mt-4">
            Oh, the staff vs. student football matches on Saturdays, the rounds
            during the study hours, and the noise of chicken dinner in the
            dining hall; Bloom is really a close-knit family.
          </p>
          <p className="mt-2">
            There are a total of 4 hostels (residential blocks), which we call
            houses, that house about 130 students.
          </p>
        </div>
        <div className="hidden md:block md:w-1/2 md:pl-8">
          <Img
            fluid={data.lalitpurResidence.childImageSharp.fluid}
            alt="Students watching a video on a laptop"
            draggable={false}
          />
        </div>
      </section>
      <section className="md:flex mt-16 md:items-center md:flex-row-reverse lg:border-l lg:border-b md:border-gray-600">
        <div className="mt-2 md:w-1/2 md:pl-8">
          <h4 className="section-head text-xl mt-4 text-red-800">
            Day Scholars
          </h4>
          <Img
            className="md:hidden"
            fluid={data.dayScholarLalitpur.childImageSharp.fluid}
            alt="Students watching a video on a laptop"
            draggable={false}
          />
          <p className="mt-4">
            Students from the local area commute on daily basis either on their
            own or via the school bus that we run covering almost all Mahalxmi
            Municipality. The students are picked up at 7 AM and returned home
            by 5 PM.
          </p>
          <p className="mt-2">
            You can explore the bus route{" "}
            <a
              href="https://www.google.com/maps/d/edit?mid=1c3WsDgtHqKTsDgXSqP1-yaBMHB5VCgTU&usp=sharing"
              className="in-link"
            >
              here
            </a>
          </p>
        </div>
        <div className="hidden md:block md:w-1/2 md:pr-8">
          <Img
            fluid={data.dayScholarLalitpur.childImageSharp.fluid}
            alt="Students watching a video on a laptop"
            draggable={false}
          />
        </div>
      </section>
      <section className="md:flex mt-16 md:items-center md:flex-row lg:border-r lg:border-b md:border-gray-600">
        <div className="mt-2 md:w-1/2 md:pr-8">
          <h4 className="section-head text-xl mt-4 text-red-800">
            Weekday Boarding Facility
          </h4>
          <Img
            className="md:hidden"
            fluid={data.weekdayBoarding.childImageSharp.fluid}
            alt="Two girl students in a namaste pose"
            draggable={false}
          />
          <p className="mt-4">
            We drop students at home on Friday, after the end of classes and
            pick up early morning before the start of the classes. This facility
            is available for subscription to all reasonable drop points inside
            the valley.
          </p>
        </div>
        <div className="hidden md:block md:w-1/2 md:pr-8">
          <Img
            fluid={data.weekdayBoarding.childImageSharp.fluid}
            alt="A boy studying"
            draggable={false}
          />
        </div>
      </section>
    </div>
  )
}
const BloomItahariFacilities = ({ className }) => {
  return (
    <div className={` ${className}`}>
      <h4 className="lg:px-16 text-xl text-red-800 lg:text-center mt-8 font-medium">
        Information about Facilities in our Itahari Campus will be uploaded soon
        in this site. Please check out in the near future.
      </h4>
    </div>
  )
}

export default function () {
  const data = useStaticQuery(graphql`
    query {
      lalitpurLocation: file(relativePath: { eq: "lalitpurLocation.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            ...GatsbyImageSharpFluid
          }
        }
      }
      internationalExposure: file(
        relativePath: { eq: "internationalExposure.jpg" }
      ) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            ...GatsbyImageSharpFluid
          }
        }
      }
      weekdayBoarding: file(relativePath: { eq: "students-namaste.jpeg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            ...GatsbyImageSharpFluid
          }
        }
      }
      dayScholarLalitpur: file(relativePath: { eq: "boy-reading.jpeg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            ...GatsbyImageSharpFluid
          }
        }
      }
      lalitpurResidence: file(relativePath: { eq: "residenceLalitpur.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const locations = ["lalitpur", "itahari"]

  const [currentSchoolLocation, setCurrentSchoolLocation] = useState("lalitpur")

  const [schoolChangeNav, setSchoolChangeNav] = useState(false)

  const schoolSelectionBox = useRef()

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (isInViewPort(schoolSelectionBox.current)) {
        setTimeout(() => {
          if (isInViewPort(schoolSelectionBox.current)) {
            setSchoolChangeNav(true)
          }
        }, 400)
      } else {
        setTimeout(() => {
          if (!isInViewPort(schoolSelectionBox.current)) {
            setSchoolChangeNav(false)
          }
        }, 400)
      }
    })
    return () => {
      document.removeEventListener("scroll", () => {})
    }
  }, [])

  const scrollToCorrectSchool = () => {
    schoolSelectionBox.current.scrollIntoView()
    window.scrollBy(0, -(window.innerHeight / 2))
  }

  return (
    <Layout>
       <SEO title="Facilities"/>
      {schoolChangeNav ? (
        <section className="sticky top-0 z-10 mx-0 my-0 px-0 py-0 no-x-margin">
          <SchoolSelection
            location={currentSchoolLocation}
            currentSchoolLocation={currentSchoolLocation}
            setCurrentSchoolLocation={setCurrentSchoolLocation}
            locations={locations}
            afterClick={scrollToCorrectSchool}
          />
        </section>
      ) : (
        ""
      )}
      {/* <section>
        <Calendar />
        <Scroll />
      </section> */}
      <section className="lg:border-r lg:border-b md:border-gray-600 md:mt-16 md:py-16">
        <div className="section-for-small-devices">
          <h1 className="section-head lg:text-center">
            Facilities and Features
          </h1>
          <p className="mt-4 lg:px-12 lg:mt-8 lg:text-center">
            We strive to be consistent with the facilities we provide across our
            network of schools. However, there are differences as demanded by
            natural factors like location, weather, etc. Please select the
            school you’d like to learn more about.
          </p>
          <div className="lg:px-16 lg:flex lg:justify-center lg:mt-12">
            {locations.map((location, index) => (
              <button
                className={`lg:block lg:mr-12 button-outline bg-gray-600 text-white py-4 px-2 my-4 mr-4 ${
                  currentSchoolLocation === location ? "bg-red-800" : ""
                }`}
                onClick={() => {
                  setCurrentSchoolLocation(location)
                  // scrollToCorrectSchool()
                }}
                key={index}
              >
                {" "}
                Bloom Nepal School,{" "}
                <span className="capitalize">{location}</span>
              </button>
            ))}
          </div>
        </div>
      </section>
      <section className="section-for-small-devices bg-transparent">
        <div ref={schoolSelectionBox}>
          <SchoolFacilities
            location={currentSchoolLocation}
            className="bg-white m-0 p-0"
            data={data}
          />
        </div>
      </section>
      <section>
        <BeABloomie />
      </section>
    </Layout>
  )
}